.searchListingsPanel {
  width: 100%;
  margin: auto;
  max-width: var(--contentMaxWidthPages);
  padding: 0 32px 0 32px;

  img {
    border-radius: 8px;
  }
}

.searchResultSummary {
  padding-top: 40px;
  margin: 10px 8px 19px 0;
}
